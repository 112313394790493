import React, { useRef, useState } from "react";
import AuthenticatedLayout from "../../layouts/AuthenticatedLayout";
import { getTicket, sendTicketMessage } from "../../queries/tickets";
import { useParams } from "react-router-dom";
import ContentLoader from "../../components/molecules/ContentLoader";
import StatDisplay from "../../components/molecules/StatDisplay";
import { useTranslation } from "react-i18next";
import { formatDateToReadable } from "../../utils/formatDate";
import TextArea from "../../components/atoms/input/TextArea";
import ChatBalloon from "../../components/organisms/tickets/chat/ChatBalloon";
import IconButton from "../../components/atoms/IconButton";
import { FaPaperPlane } from "react-icons/fa";
import FileUploader, { FileUploaderActions } from "../../components/molecules/FileUploader";
import { useQueryClient } from "react-query";
import TicketStatus from "../../models/enums/ticket-status.enum";
import { TicketDetailResponse } from "../../models/types/api/ticket-detail.type";
import Alert from "../../components/atoms/Alert";
import useAuth from "../../hooks/useAuth";
import { getOwnerManagementContractTicket } from "../../queries/ownerTickets";

export default function TicketPage(): JSX.Element {
	const [ message, setMessage ] = useState("");
	const [ selectedFiles, setSelectedFiles ] = useState<File[]>([]);
	const user = useAuth();
	const {
		id, ticketId, ownerManagementContractId,
	} = useParams();
	const parsedId = parseInt(id ?? "");

	const { t } = useTranslation("tickets");
	const fileRef = useRef<FileUploaderActions>(null);
	const [isOwnerView] = useState(user.getCapabilities().includes("OwnerTicketCorrespondence"));
	const queryClient = useQueryClient();

	const ticket = isOwnerView ? getOwnerManagementContractTicket(parseInt(ownerManagementContractId ?? ""), parseInt(ticketId ?? "")) : getTicket(parsedId);

	const mutation = sendTicketMessage(parsedId, {
		onSuccess() {
			setMessage("");
			setSelectedFiles([]);

			void queryClient.invalidateQueries([ "ticket", parsedId ]);
		},
	});

	const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
		setMessage(event.target.value);
	};

	const handleSubmit = (event: React.FormEvent): void => {
		event.preventDefault();
		mutation.mutate({
			message,
			files: selectedFiles,
		});
	};

	const isArchived = (currentTicket: TicketDetailResponse): boolean => {
		const status : TicketStatus
			= TicketStatus[currentTicket.statusId as unknown as keyof typeof TicketStatus];
		return status === TicketStatus.AFGEHANDELDDOORDIENSTVERLENER
			|| status === TicketStatus.AFGEHANDELDDOORHUURDER
			|| status === TicketStatus.AFGEHANDELDHANDMATIG;
	};
	return (
		<AuthenticatedLayout title={t("stats.title")}>
			{
				ticket.isLoading ? <ContentLoader />
					: !ticket.data ? (
						<Alert variant="error">
							{t("errors.ticketNotFound")}
						</Alert>
					) : (
						<div className="flex flex-col gap-2">
							<div className="basis-full border-b">
								<StatDisplay
									title={t("stats.ticketId")}
									value={ticket.data.ticketId.toString()}
								/>

								<StatDisplay
									title={t("stats.status")}
									value={t(`status.${ticket.data.statusId ?? "NIEUW"}`)}
								/>

								<StatDisplay
									title={t("stats.reporter")}
									value={ticket.data.createdBy ?? t("errors.missingReporter")}
								/>

								<StatDisplay
									title={t("stats.date")}
									value={
										formatDateToReadable(ticket.data.createdOn)
									}
								/>

								<StatDisplay
									title={t("stats.location")}
									value={t(`location.${ticket.data.locationId ?? "OVERIG"}`)}
								/>

								<StatDisplay
									title={t("stats.category")}
									value={t(`category.${ticket.data.categoryId ?? "OVERIG"}`)}
								/>
							</div>

							<div className="flex flex-col basis-full justify-end gap-2">
								<div className="flex flex-col-reverse overflow-auto h-[45vh]">
									{ticket.data.messages
										.map(ticketMessage => (
											<ChatBalloon
												ticketId={ticket.data.ticketId}
												message={ticketMessage}
												key={ticketMessage.ticketMessageId}
											/>
										))}
								</div>

								{
									isArchived(ticket.data) || isOwnerView ? (
										<Alert variant="warning">
											{t("errors.ticketNoAccess")}
										</Alert>
									) : (
										<>
											<form className="flex flex-row items-end gap-2" onSubmit={handleSubmit}>
												<div className="grow">
													<TextArea
														label=""
														value={message}
														onChange={handleChange}
														placeholder={t("chat.placeholder")}
													/>
												</div>

												<IconButton
													disabled={message.length < 10 && !mutation.isLoading}
													type="submit"
												>
													<FaPaperPlane />
												</IconButton>
											</form>

											<FileUploader
												filesUpdateHandler={setSelectedFiles}
												max={Infinity}
												files={selectedFiles}
												ref={fileRef}
											/>
										</>
									)
								}

							</div>
						</div>
					)
			}
		</AuthenticatedLayout>
	);
}
