import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import useManagementContracts from "../hooks/useManagementContracts";
import Alert from "../components/atoms/Alert";
import Table from "../components/atoms/table/Table";
import TableHead from "../components/atoms/table/TableHead";
import TableRow from "../components/atoms/table/TableRow";
import TableCell from "../components/atoms/table/TableCell";
import { getRentalInvoices } from "../queries/managementContracts";
import formatNumber from "../utils/formatNumber";
import { formatPeriod } from "../utils/formatDate";
import AuthenticatedLayout from "../layouts/AuthenticatedLayout";
import TableBody from "../components/atoms/table/TableBody";
import { DateTime } from "luxon";

export default function RentArrearsPage(): JSX.Element {
	const { t } = useTranslation("rentArrears");

	const contracts = useManagementContracts();

	const rentalInvoice = getRentalInvoices();

	useEffect(() => {
		void rentalInvoice.refetch();
	}, [contracts.currentContractId]);

	return (
		<AuthenticatedLayout title={t("title")} showManagementContracts>
			{rentalInvoice.isError ? (
				<Alert variant="error">
					{t("default.error")}
				</Alert>
			) : rentalInvoice.data
                && rentalInvoice.data.length > 0
				? (
					<Table>
						<TableHead>
							<TableRow>
								<TableCell header>
									{t("table.invoice-no")}
								</TableCell>

								<TableCell header>
									{t("table.tenant")}
								</TableCell>

								<TableCell header>
									{t("table.invoice-period")}
								</TableCell>

								<TableCell header alignment="left">
									{t("table.invoice-amount")}
								</TableCell>

								<TableCell header alignment="left">
									{t("table.unpaid-amount")}
								</TableCell>
							</TableRow>
						</TableHead>

						<TableBody>
							{rentalInvoice.data.filter(rentalContract =>
								(DateTime.now()
									>= DateTime.fromISO(rentalContract.dueDate)))
								.map(rentArrears => (
									<TableRow key={`${contracts.currentContractId}-${rentArrears.invoiceNo}`}>
										<TableCell className="w-1/9 min-w-fit shrink">
											{rentArrears.invoiceNo}
										</TableCell>

										<TableCell className="w-1/3 min-w-fit left-0 md:right-0 ">
											{rentArrears.tenant}
										</TableCell>

										<TableCell alignment="left" className="w-1/3 min-w-fit whitespace-nowrap">
											{formatPeriod(
												rentArrears.lines.map( line => (
													{
														periodFrom: line.periodFrom,
														periodTo: line.periodTo,
													})
												)
											)}
										</TableCell>

										<TableCell alignment="right" className="w-1/9 min-w-fit whitespace-nowrap shrink">
											{ `€ ${ formatNumber(rentArrears.invoiceAmount) }` }
										</TableCell>

										<TableCell alignment="right" className="w-1/9 min-w-fit whitespace-nowrap shrink">
											{ `€ ${ formatNumber(rentArrears.unpaidAmount) }` }
										</TableCell>
									</TableRow>
								))}
						</TableBody>

					</Table>
				)
				: (
					<Alert>
						{t("empty_result")}
					</Alert>
				)}
		</AuthenticatedLayout>
	);
}
