import React from "react";
import ReactDOM from "react-dom/client";
import "./style/index.css";
import reportWebVitals from "./reportWebVitals";

import "./i18n";
import { EnvironmentProvider } from "./hooks/useEnvironment";
import { QueryClient, QueryClientProvider } from "react-query";
import { AuthProvider } from "./hooks/useAuth";
import Router from "./Router";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ValidationProvider } from "./hooks/useValidation";
import { CountryProvider } from "./hooks/useCountries";
import { ManagementContractsProvider } from "./hooks/useManagementContracts";
import "react-tooltip/dist/react-tooltip.css";

const client = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			retry: false,
			suspense: true,
		},
	},
});

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);

root.render(
	<EnvironmentProvider>
		<AuthProvider>
			<CountryProvider>
				<ValidationProvider>
					<QueryClientProvider client={client}>
						<ManagementContractsProvider>
							<ToastContainer limit={3} />

							<Router />
						</ManagementContractsProvider>
					</QueryClientProvider>
				</ValidationProvider>
			</CountryProvider>
		</AuthProvider>
	</EnvironmentProvider>
);

if (process.env.NODE_ENV !== "production") {
	// Dit is altijd in development, dus dan is een console.log acceptabel
	// eslint-disable-next-line no-console
	reportWebVitals(console.log).catch(console.error);
}
